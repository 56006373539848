export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/accounting": [3],
		"/addons": [4],
		"/audit-logs": [5],
		"/bills": [6],
		"/customers": [7],
		"/dashboard": [8],
		"/foodcosting": [9],
		"/foodcosting/categories": [10],
		"/foodcosting/cost-analysis/dashboard": [11],
		"/foodcosting/cost-analysis/recipe/[id]": [12],
		"/foodcosting/ingredientcost": [13],
		"/foodcosting/ingredients": [14],
		"/foodcosting/recipes": [15],
		"/inventory": [16],
		"/login": [17],
		"/notifications": [18],
		"/pos": [19],
		"/pos/[tableid]": [20],
		"/pos/[tableid]/[ticketid]/ticket": [21],
		"/production/batches": [22],
		"/production/logs": [23],
		"/purchases": [24],
		"/register": [25],
		"/reports": [26],
		"/reservation-seating": [27],
		"/reservation-seating/guests": [28],
		"/reservation-seating/reservations": [29],
		"/reservation-seating/tables": [30],
		"/reservation-seating/waiting-list": [31],
		"/settings": [32],
		"/setup": [33],
		"/shifts": [34],
		"/superadmin": [35],
		"/superadmin/config": [36],
		"/superadmin/tenants": [37],
		"/tickets": [38],
		"/users": [39],
		"/users/login": [40],
		"/users/profile": [41],
		"/users/roles": [42],
		"/vendors": [43],
		"/wastage": [44],
		"/wastage/logs": [45],
		"/wastage/reports": [46]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';